import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BlogContent, PageLayout } from '../components';
import { useTranslation } from 'react-i18next';
import { FRONTEND_URL } from '../utils';

const BlogPage = () => {
  const { t: translate } = useTranslation();

  const crumbsBlogPage = [
    {
      label: translate('menu.main'),
      path: '/',
    },
    {
      label: translate('menu.blog'),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{translate('titles.blog')} - Zennovaton - IT company</title>
        <meta name="description" content={translate('seo.blogDesc')} />
        <link rel="canonical" href={`${FRONTEND_URL}blog/all/`} />
      </Helmet>
      <PageLayout>
        <BlogContent crumbs={crumbsBlogPage} />
      </PageLayout>
    </>
  );
};

export default BlogPage;
