import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        menu: {
          main: 'Main',
          about: 'About Us',
          services: 'Services',
          cases: 'Cases',
          team: 'Team',
          blog: 'Blog',
          testimonials: 'Testimonials',
          contacts: 'Contacts',
        },
        btns: {
          aboutBtn: 'View our services',
          findMore: 'Find out more',
          close: 'Close',
          connection: 'Contact us',
          seeAll: 'See all',
          sendMsg: 'Send message',
        },
        titles: {
          service: 'Our services',
          cases: 'Cases',
          team: 'Team',
          blog: 'Blog',
          blogArticle: 'Blog Aticle',
          testimonials: 'Testimonials',
        },
        footer: '2024 ZNN. All rights reserved.',
        loading: 'Loading...',
        dateNoSpecified: 'Date not specified',
        dataPublish: 'Date of publication',
        dataUpdate: 'Date of update',
        aboutSection: {
          title: 'Between Zen and Innovation',
          text: `Zennovation (ZNN) — We combine cutting-edge technologies with a harmonious approach to development, creating
                solutions that work efficiently and stably. Our team of specialists has extensive experience in
                developing commercial solutions and open source technologies. We successfully implement projects on design and
                creation of websites, web tools, widgets and mobile applications of varying complexity, helping our clients
                achieve their goals with the help of modern and reliable IT solutions or simply make the world a better place.`,
        },
        contactSection: {
          title: 'Contact us',
          ourContact: 'Our contacts',
          yourMsg: 'Your message',
          phoneRB: 'Telephone (RB)',
          phoneRF: 'Telephone (RUS)',
          email: 'Email',
          inputName: 'Your name',
          inputEmail: 'Your Email',
          inputMsg: 'Your message',
          successForm: 'Message sent successfully!',
          errorForm: 'Error sending message',
        },
        seo: {
          mainDesc:
            'ZNN - innovative solutions for business automation. Development of web applications and PWAs, integration with Shopify and creation of custom API solutions.',
          blogDesc:
            'Read the latest articles about web application development, PWA and innovative business automation solutions from ZNN',
        },
      },
    },
    ru: {
      translation: {
        menu: {
          main: 'Главная',
          about: 'О нас',
          services: 'Услуги',
          cases: 'Кейсы',
          team: 'Команда',
          blog: 'Блог',
          blogArticle: 'Статья блога',
          testimonials: 'Отзывы',
          contacts: 'Контакты',
        },
        btns: {
          aboutBtn: 'Ознакомиться с нашими услугами',
          findMore: 'Узнать больше',
          close: 'Закрыть',
          connection: 'Связаться с нами',
          seeAll: 'Смотреть все',
          sendMsg: 'Отправить сообщение',
        },
        titles: {
          service: 'Наши услуги',
          cases: 'Кейсы',
          team: 'Команда',
          blog: 'Блог',
          testimonials: 'Отзывы',
        },
        footer: '2024 ZNN. Все права защищены.',
        loading: 'Загрузка...',
        dateNoSpecified: 'Дата не указана',
        dataPublish: 'Дата публикации',
        dataUpdate: 'Дата обновления',
        aboutSection: {
          title: 'Между дзеном и инновациями',
          text: `Zennovation (ZNN) — Мы объединяем передовые технологии с гармоничным подходом к разработке, создавая
            решения, которые работают эффективно и стабильно. Наша команда специалистов обладает богатым опытом в
            разработке коммерческих решений и опенсорс технологий. Мы успешно реализуем проекты по дизайну и созданию
            веб-сайтов, веб-инструментов, виджетов и мобильных приложений разной сложности, помогая нашим клиентам
            достигать их целей с помощью современных и надежных IT-решений или просто делать мир лучше.`,
        },
        contactSection: {
          title: 'Свяжитесь с нами',
          ourContact: 'Наши контакты',
          yourMsg: 'Ваше сообщение',
          phoneRB: 'Телефон (РБ)',
          phoneRF: 'Телефон (РФ)',
          email: 'Электронная почта',
          inputName: 'Ваше имя',
          inputEmail: 'Ваш Email',
          inputMsg: 'Ваше сообщение',
          successForm: 'Сообщение успешно отправлено!',
          errorForm: 'Ошибка при отправке сообщения',
        },
        seo: {
          mainDesc:
            'ZNN - инновационные решения для автоматизации бизнеса. Разработка веб-приложений и PWA, интеграция с Shopify и создание кастомных API-решений.',
          blogDesc:
            'Читайте последние статьи о разработке веб-приложений, PWA и инновационных решениях для автоматизации бизнеса от ZNN',
        },
      },
    },
  },
  lng: 'ru',
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
