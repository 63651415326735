import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButtonComponent } from './ui';

const Modal = ({ isOpen, onClose, title, details }) => {
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white md:rounded-3xl shadow-lg p-8 flex flex-col w-full h-full md:w-[660px] md:h-[720px] mx-0">
        <h3 className="text-2xl font-semibold mb-4 text-gray-900 flex-shrink-0">{title}</h3>
        <div
          className="mb-6 text-gray-700 whitespace-pre-wrap overflow-y-auto flex-grow"
          style={{ maxHeight: 'calc(100% - 120px)' }}
        >
          {details}
        </div>
        <div className="flex justify-end space-x-4 flex-shrink-0">
          <CustomButtonComponent size="large" onClick={onClose}>
            {translate('btns.close')}
          </CustomButtonComponent>
          <a
            href="#contact"
            className="px-4 py-2 text-white bg-gray-800 rounded-full hover:bg-gray-800 flex-shrink-0"
            onClick={onClose}
          >
            {translate('btns.connection')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
