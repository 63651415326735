import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils';

const ArticleDates = ({ publishedAt, updatedAt }) => {
  const { t: translate } = useTranslation();

  return (
    <div className="flex justify-between items-center gap-2 mb-4">
      <div className="text-lg text-gray-500 flex flex-wrap justify-start">
        <span className="text-sm md:text-lg text-gray-700 mr-1 text-left">{translate('dataPublish')}:</span>
        <span className="text-sm md:text-lg text-gray-500 text-left">
          {formatDate(publishedAt ?? translate('dateNoSpecified'))}
        </span>
      </div>
      <div className="flex flex-wrap justify-end gap-[2px]">
        <span className="text-sm md:text-lg text-gray-700 mr-1 text-right">{translate('dataUpdate')}:</span>
        <span className="text-sm md:text-lg text-gray-500 text-right">
          {formatDate(updatedAt ?? translate('dateNoSpecified'))}
        </span>
      </div>
    </div>
  );
};

export default ArticleDates;
