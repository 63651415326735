import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PageLayout, SingleArticleContent } from '../components';
import { useBlogPost } from '../hooks';
import { Loading, TitlePage } from '../components/ui';
import { FRONTEND_URL, URLS } from '../utils';
import { useLanguage } from '../context/langContext';
import { useTranslation } from 'react-i18next';

const ArticlePage = () => {
  const { id } = useParams();
  const { post, isLoading } = useBlogPost(id);
  const [crumbs, setCrumbs] = useState([]);
  const { language } = useLanguage();
  const { t: translate } = useTranslation();

  const { title, titleEn, description, descriptionEn } = post?.attributes || {};

  const localizedTitle = language === 'ru' ? title : titleEn;
  const localizedDescription = language === 'ru' ? description : descriptionEn;

  useEffect(() => {
    if (post?.attributes) {
      setCrumbs([
        { label: translate('menu.main'), path: URLS.MAIN },
        { label: translate('menu.blog'), path: URLS.BLOG },
        { label: localizedTitle || translate('loading') },
      ]);
    }
  }, [post, localizedTitle, translate]);

  return (
    <>
      <Helmet>
        <title>{localizedTitle}</title>
        <meta name="description" content={localizedDescription} />
        <link rel="canonical" href={`${FRONTEND_URL}blog/${id}/`} />
      </Helmet>
      <PageLayout>
        <section className="min-h-screen flex flex-col items-center bg-gradient-to-r from-gray-100 to-gray-200 pt-28 pb-20">
          {isLoading ? (
            <div className="mx-auto mb-6 md:mb-12">
              <Loading />
            </div>
          ) : (
            <>
              <TitlePage text={localizedTitle} crumbs={crumbs} />
              <div className="mb-12 w-full max-w-5xl px-4">
                <SingleArticleContent post={post} />
              </div>
            </>
          )}
        </section>
      </PageLayout>
    </>
  );
};

export default ArticlePage;
