import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { TitleBlock, CustomButtonComponent } from './ui';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const { t: translate } = useTranslation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    setError(null);

    try {
      const response = await fetch('https://api.z-nn.site/api/applications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: formData,
        }),
      });

      if (response.ok) {
        setIsSubmitted(true);
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        const result = await response.json();
        setError(result.error.message || translate('contactSection.errorForm'));
      }
    } catch (error) {
      setError(translate('contactSection.errorForm'));
    }
  };

  return (
    <div
      id="contact"
      className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 px-8 py-20"
    >
      <TitleBlock title={translate('contactSection.title')} />

      <div className="w-full max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center items-start bg-white shadow-lg rounded-lg p-6 md:p-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">{translate('contactSection.ourContact')}:</h3>
          <p className="text-gray-600 mb-4">
            {translate('contactSection.phoneRB')}:
            <a href="tel:+375445235052" className="text-gray-900 hover:text-gray-600 transition-colors">
              +375445235052
            </a>
          </p>
          <p className="text-gray-600 mb-4">
            {translate('contactSection.phoneRF')}:
            <a href="tel:+79801073492" className="text-gray-900 hover:text-gray-600 transition-colors">
              +79801073492
            </a>
          </p>
          <p className="text-gray-600 mb-4">
            {translate('contactSection.email')}:
            <a href="mailto:sup@z-nn.site" className="text-gray-900 hover:text-gray-600 transition-colors">
              sup@z-nn.site
            </a>
          </p>
          <div className="flex justify-center mt-4 space-x-4 w-full">
            <a
              href="https://www.linkedin.com/company/znn/about/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-700 text-white p-3 rounded-full shadow-lg hover:bg-blue-800 transition-colors"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://t.me/ZENNOVATION"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
            >
              <TelegramIcon />
            </a>
            <a
              href="https://wa.me/79801073492"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-colors"
            >
              <WhatsAppIcon />
            </a>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start bg-white shadow-lg rounded-lg p-6 md:p-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">{translate('contactSection.yourMsg')}</h3>
          {isSubmitted ? (
            <p className="text-green-600 text-center">{translate('contactSection.successForm')}</p>
          ) : (
            <form className="w-full" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder={translate('contactSection.inputName')}
                value={formData.name}
                onChange={handleInputChange}
                className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none"
                style={{ boxShadow: 'none' }}
                onFocus={(e) => (e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)')}
                onBlur={(e) => (e.target.style.boxShadow = 'none')}
              />
              <input
                type="email"
                name="email"
                placeholder={translate('contactSection.inputEmail')}
                value={formData.email}
                onChange={handleInputChange}
                className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none"
                style={{ boxShadow: 'none' }}
                onFocus={(e) => (e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)')}
                onBlur={(e) => (e.target.style.boxShadow = 'none')}
              />
              <textarea
                name="message"
                placeholder={translate('contactSection.inputMsg')}
                value={formData.message}
                onChange={handleInputChange}
                className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none h-32 resize-none"
                style={{ boxShadow: 'none' }}
                onFocus={(e) => (e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)')}
                onBlur={(e) => (e.target.style.boxShadow = 'none')}
              />
              {error && <p className="text-red-600 mb-4">{error}</p>}
              <div className="flex justify-center">
                <CustomButtonComponent type="submit">{translate('btns.sendMsg')}</CustomButtonComponent>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
