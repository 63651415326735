const servicesData = [
  {
    title: { ru: 'Разработка сайта', en: 'Website development' },
    description: {
      ru: 'Мы создаем современные, адаптивные и функциональные веб-сайты под ключ.',
      en: 'We create modern, responsive and functional turnkey websites.',
    },
    hoverColor: 'rgba(129, 206, 160, 0.3)',
    details: {
      ru: `
          Что входит в услугу:
          - Анализ требований:
            - Встреча с клиентом для обсуждения бизнес-целей и задач.
            - Определение целевой аудитории и её потребностей.
            - Сбор и анализ требований, включая функциональные и нефункциональные.
          - Прототипирование:
            - Создание wireframes для определения структуры страниц.
            - Разработка интерактивных прототипов для оценки навигации и пользовательского опыта.
            - Итерации и согласования с клиентом.
          - Дизайн:
            - Создание уникального и визуально привлекательного дизайна, соответствующего бренду.
            - Разработка цветовой схемы, типографики и графических элементов.
            - Обеспечение адаптивности дизайна для различных устройств и экранов.
          - Разработка:
            - Верстка макетов с использованием HTML, CSS, JavaScript, обеспечивая кроссбраузерную совместимость.
            - Разработка серверной части с использованием современных технологий (например, Node.js, PHP, Ruby on Rails).
            - Интеграция с системами управления контентом (CMS) и внешними API.
          - Тестирование:
            - Функциональное тестирование для проверки всех возможностей сайта.
            - Тестирование производительности для обеспечения быстрого времени отклика.
            - Тестирование на различных устройствах и браузерах для кроссплатформенной совместимости.
          - Запуск:
            - Развертывание сайта на хостинге клиента или предоставление услуг хостинга.
            - Обучение сотрудников клиента работе с системой управления сайтом.
            - Предоставление документации и технической поддержки на этапе запуска.
        `,
      en: `
          What is included in the service:
          - Requirements analysis:
          - Meeting with the client to discuss business goals and objectives.
          - Defining the target audience and its needs.
          - Gathering and analyzing requirements, including functional and non-functional.
          - Prototyping:
          - Creating wireframes to determine the page structure.
          - Developing interactive prototypes to evaluate navigation and user experience.
          - Iterations and approvals with the client.
          - Design:
          - Creating a unique and visually appealing design that matches the brand.
          - Developing a color scheme, typography and graphic elements.
          - Ensuring that the design is responsive for different devices and screens.
          - Development:
          - Layout of layouts using HTML, CSS, JavaScript, ensuring cross-browser compatibility.
          - Development of the server part using modern technologies (for example, Node.js, PHP, Ruby on Rails).
          - Integration with content management systems (CMS) and external APIs.
          - Testing:
          - Functional testing to check all the capabilities of the site.
          - Performance testing to ensure fast response time.
          - Testing on various devices and browsers for cross-platform compatibility.
          - Launch:
          - Deployment of the site on the client's hosting or provision of hosting services.
          - Training of the client's employees to work with the content management system.
          - Provision of documentation and technical support at the launch stage.
        `,
    },
  },
  {
    title: {
      ru: 'Разработка программного обеспечения',
      en: 'Software Development',
    },
    description: {
      ru: 'Создание высококачественного программного обеспечения и приложений.',
      en: 'Creating high-quality software and applications.',
    },
    hoverColor: 'rgba(193, 148, 68, 0.3)',
    details: {
      ru: `
          Что входит в услугу:
          - Анализ задач:
            - Определение ключевых задач, которые должен решать программный продукт.
            - Сбор и документирование требований, включая функциональные спецификации и нефункциональные ограничения.
          - Проектирование архитектуры:
            - Разработка архитектуры системы, включая выбор подходящих технологий и паттернов проектирования.
            - Моделирование базы данных и определение ключевых сущностей.
            - Планирование модульности и масштабируемости приложения.
          - Разработка:
            - Разработка модулей и компонентов системы согласно архитектурному плану.
            - Регулярные ревью кода и тестирование модулей для обеспечения высокого качества.
            - Интеграция с внешними сервисами и API.
          - Тестирование:
            - Юнит-тестирование для проверки отдельных модулей.
            - Интеграционное тестирование для проверки взаимодействия модулей.
            - Регрессионное тестирование после внесения изменений.
          - Внедрение:
            - Развертывание программного обеспечения в продакшн-среде.
            - Обучение конечных пользователей и предоставление документации.
            - Оказание технической поддержки в период внедрения.
          - Поддержка:
            - Регулярные обновления программного обеспечения для обеспечения его актуальности.
            - Мониторинг производительности и доступности.
            - Реагирование на запросы пользователей и устранение возникших проблем.
        `,
      en: `
          What is included in the service:
          - Task analysis:
          - Defining the key tasks that the software product should solve.
          - Collecting and documenting requirements, including functional specifications and non-functional constraints.
          - Architecture design:
          - Developing the system architecture, including selecting appropriate technologies and design patterns.
          - Modeling the database and defining key entities.
          - Planning the modularity and scalability of the application.
          - Development:
          - Developing system modules and components according to the architectural plan.
          - Regular code reviews and module testing to ensure high quality.
          - Integration with external services and APIs.
          - Testing:
          - Unit testing to check individual modules.
          - Integration testing to check the interaction of modules.
          - Regression testing after changes are made.
          - Implementation:
          - Deployment of software to production environment.
          - End user training and documentation.
          - Provision of technical support during implementation.
          - Maintenance:
          - Regular software updates to ensure software is up-to-date.
          - Performance and availability monitoring.
          - Responding to user requests and troubleshooting any issues that arise.
        `,
    },
  },
  {
    title: {
      ru: 'Разработка мобильных приложений и PWA',
      en: 'Mobile App and PWA Development',
    },
    description: {
      ru: 'Создание мобильных приложений и прогрессивных веб-приложений (PWA) для любых платформ.',
      en: 'Creating mobile apps and progressive web apps (PWA) for any platform.',
    },
    hoverColor: 'rgba(95, 111, 170, 0.3)',
    details: {
      ru: `
          Что входит в услугу:
          - Идея и планирование:
            - Определение ключевых функций приложения на основе требований клиента.
            - Анализ целевой аудитории и конкурентного окружения.
            - Создание дорожной карты проекта, включая временные рамки и этапы разработки.
          - Проектирование интерфейса:
            - Создание прототипов интерфейса для визуализации пользовательского опыта.
            - Проектирование навигации и интерфейсов, ориентированных на удобство пользователя.
            - Итерации и тестирование прототипов с пользователями.
          - Разработка:
            - Разработка нативных мобильных приложений для iOS и Android с использованием Swift, Kotlin, или других подходящих технологий.
            - Создание PWA с использованием современных фронтенд-фреймворков (например, React, Angular, Vue.js).
            - Интеграция с бэкендом, API, и сторонними сервисами.
          - Тестирование:
            - Тестирование производительности и отзывчивости приложения на различных устройствах.
            - Проверка совместимости с различными версиями операционных систем.
            - Автоматизированное и ручное тестирование для выявления и исправления ошибок.
          - Публикация:
            - Подготовка приложения к публикации в App Store и Google Play.
            - Оптимизация приложения для магазинов приложений (ASO).
            - Выпуск PWA с поддержкой всех основных функций, включая офлайн-режим.
          - Поддержка и обновления:
            - Обновление приложения с учетом изменений в операционных системах.
            - Внедрение новых функций и улучшение существующих на основе пользовательского фидбэка.
            - Мониторинг производительности и оперативное исправление багов.
        `,
      en: `
          What's included in the service:
          - Idea and planning:
          - Defining key app features based on client requirements.
          - Analyzing the target audience and competitive environment.
          - Creating a project roadmap, including timeframes and development stages.
          - Interface design:
          - Creating interface prototypes to visualize the user experience.
          - Designing navigation and interfaces focused on user convenience.
          - Iterating and testing prototypes with users.
          - Development:
          - Developing native mobile apps for iOS and Android using Swift, Kotlin, or other suitable technologies.
          - Creating PWAs using modern frontend frameworks (e.g. React, Angular, Vue.js).
          - Integration with backend, API, and third-party services.
          - Testing:
          - Testing the performance and responsiveness of the application on various devices.
          - Checking compatibility with different versions of operating systems.
          - Automated and manual testing to identify and fix errors.
          - Publishing:
          - Preparing the application for publication in the App Store and Google Play.
          - Optimizing the application for app stores (ASO).
          - Releasing PWA with support for all major features, including offline mode.
          - Support and updates:
          - Updating the application to take into account changes in operating systems.
          - Implementing new features and improving existing ones based on user feedback.
          - Monitoring performance and promptly fixing bugs.
        `,
    },
  },
  {
    title: {
      ru: 'Поддержка и доработка вашего продукта',
      en: 'Support and development of your product',
    },
    description: {
      ru: 'Мы оказываем услуги по поддержке и развитию вашего существующего программного продукта.',
      en: 'We provide services for the support and development of your existing software product.',
    },
    hoverColor: 'rgba(193, 148, 68, 0.3)',
    details: {
      ru: `
          Что входит в услугу:
          - Регулярные обновления:
            - Обеспечение актуальности программного обеспечения с учетом изменений в законодательстве и нормативных актах.
            - Внедрение обновлений безопасности для защиты от новых угроз.
            - Обновление зависимостей и библиотек для обеспечения стабильности работы системы.
          - Оптимизация:
            - Анализ производительности программного обеспечения и выявление узких мест.
            - Внедрение оптимизаций для улучшения скорости и уменьшения использования ресурсов.
            - Обновление архитектуры для улучшения масштабируемости и гибкости системы.
          - Добавление новых функций:
            - Анализ потребностей бизнеса и внедрение новых возможностей в программный продукт.
            - Планирование и разработка новых модулей или функций.
            - Интеграция новых возможностей с существующей системой.
          - Исправление ошибок:
            - Оперативное выявление и исправление критических багов.
            - Мониторинг логов и пользовательских отзывов для выявления и устранения проблем.
            - Обеспечение стабильной и бесперебойной работы системы.
        `,
      en: `
          What is included in the service:
          - Regular updates:
          - Ensuring that the software is up-to-date with changes in legislation and regulations.
          - Implementing security updates to protect against new threats.
          - Updating dependencies and libraries to ensure system stability.
          - Optimization:
          - Analyzing software performance and identifying bottlenecks.
          - Implementing optimizations to improve speed and reduce resource usage.
          - Updating the architecture to improve scalability and flexibility of the system.
          - Adding new features:
          - Analyzing business needs and implementing new features into the software product.
          - Planning and developing new modules or features.
          - Integrating new features with the existing system.
          - Bug fixing:
          - Promptly identify and fix critical bugs.
          - Monitor logs and user feedback to identify and fix problems.
          - Ensure stable and uninterrupted operation of the system.
          `,
    },
  },
  {
    title: {
      ru: 'Аудит безопасности',
      en: 'Security Audit',
    },
    description: {
      ru: 'Проводим комплексный аудит безопасности для выявления уязвимостей в ваших системах.',
      en: 'We conduct a comprehensive security audit to identify vulnerabilities in your systems.',
    },
    hoverColor: 'rgba(95, 111, 170, 0.3)',
    details: {
      ru: `
          Что входит в услугу:
          - Проверка на уязвимости:
            - Проведение тестирования на проникновение для выявления слабых мест в системе.
            - Анализ безопасности сетевой инфраструктуры и серверов.
            - Проверка соответствия системы требованиям безопасности, таким как GDPR или ISO 27001.
          - Анализ риска:
            - Оценка потенциального ущерба от возможных угроз и уязвимостей.
            - Разработка плана по минимизации рисков и устранению критических уязвимостей.
            - Создание рекомендаций по улучшению текущих мер безопасности.
          - Рекомендации по усилению защиты:
            - Внедрение передовых практик по защите данных и предотвращению утечек.
            - Настройка и оптимизация систем безопасности, таких как файрволы, системы обнаружения вторжений (IDS), и антивирусные решения.
            - Подготовка отчета по результатам аудита с подробным описанием всех выявленных уязвимостей и предложениями по их устранению.
        `,
      en: `
          What is included in the service:
          - Vulnerability testing:
          - Penetration testing to identify weaknesses in the system.
          - Analysis of the security of the network infrastructure and servers.
          - Checking the system's compliance with security requirements, such as GDPR or ISO 27001.
          - Risk analysis:
          - Assessing the potential damage from possible threats and vulnerabilities.
          - Developing a plan to minimize risks and eliminate critical vulnerabilities.
          - Creating recommendations for improving current security measures.
          - Recommendations for strengthening protection:
          - Implementation of best practices for data protection and leak prevention.
          - Setting up and optimizing security systems such as firewalls, intrusion detection systems (IDS), and antivirus solutions.
          - Preparing an audit report with a detailed description of all identified vulnerabilities and suggestions for their elimination.
        `,
    },
  },
  {
    title: {
      ru: 'SEO продвижение и ведение соц сетей',
      en: 'SEO promotion and social media management',
    },
    description: {
      ru: 'Комплексное SEO продвижение и профессиональное ведение социальных сетей для вашего бизнеса.',
      en: 'Comprehensive SEO promotion and professional social media management for your business.',
    },
    hoverColor: 'rgba(129, 206, 160, 0.3)',
    details: {
      ru: `
        Что входит в услугу:
        - Аудит сайта:
          - Анализ текущего состояния сайта с точки зрения SEO: проверка индексации, структуры ссылок, контента, метаданных и скорости загрузки.
          - Выявление слабых мест и составление плана по их улучшению.
        - Разработка стратегии продвижения:
          - Определение ключевых слов и фраз, которые помогут привлечь целевую аудиторию.
          - Создание контент-плана с учетом сезонности и актуальных трендов.
          - Планирование и внедрение кампаний в социальных сетях для увеличения охвата и вовлеченности.
        - SEO-оптимизация:
          - Оптимизация контента сайта для повышения его релевантности поисковым запросам.
          - Работа с внутренней перелинковкой и структурой сайта для улучшения индексации.
          - Внедрение технических изменений, таких как улучшение скорости загрузки страниц и исправление ошибок.
        - Ведение аккаунтов в социальных сетях:
          - Создание и публикация регулярного контента, который отражает ценности бренда и интересы аудитории.
          - Проведение рекламных кампаний в социальных сетях для привлечения новой аудитории.
          - Мониторинг и анализ эффективности кампаний с последующей корректировкой стратегии.
        `,
      en: `
        What is included in the service:
        - Website audit:
        - Analysis of the current state of the site from an SEO point of view: checking indexing, link structure, content, metadata and loading speed.
        - Identifying weak points and drawing up a plan for their improvement.
        - Developing a promotion strategy:
        - Defining keywords and phrases that will help attract the target audience.
        - Creating a content plan taking into account seasonality and current trends.
        - Planning and implementing social media campaigns to increase reach and engagement.
        - SEO optimization:
        - Optimizing site content to increase its relevance to search queries.
        - Working with internal linking and site structure to improve indexing.
        - Implementing technical changes such as improving page loading speed and fixing errors.
        - Managing social media accounts:
        - Creating and publishing regular content that reflects brand values ​​and audience interests.
        - Conducting social media advertising campaigns to attract new audiences.
        - Monitoring and analyzing campaign performance with subsequent strategy adjustments.
        `,
    },
  },
];

export default servicesData;
