import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading, TitlePage } from './ui';
import { CardBlog } from '.';
import { useBlogPosts } from '../hooks';
import { useLanguage } from '../context/langContext';

const BlogContent = ({ crumbs }) => {
  const { posts, isLoading } = useBlogPosts();
  const { language } = useLanguage();
  const { t: translate } = useTranslation();

  return (
    <section
      id="services"
      className="min-h-screen flex flex-col items-center bg-gradient-to-r from-gray-100 to-gray-200 py-28"
    >
      <TitlePage text={translate('titles.blog')} crumbs={crumbs} />
      {isLoading ? (
        <div className="mx-auto mb-6 md:mb-12">
          <Loading />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl px-4">
          {posts.map(({ id, attributes }) => {
            const title = language === 'ru' ? attributes.title : attributes.titleEn;
            const description = language === 'ru' ? attributes.description : attributes.descriptionEn;
            const fullText = language === 'ru' ? attributes.fullText : attributes.fullTextEn;

            return (
              <CardBlog
                key={id}
                id={id}
                title={title}
                image={attributes.image?.data?.attributes?.formats?.medium?.url}
                description={description}
                fullText={fullText}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};

export default BlogContent;
