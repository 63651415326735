import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButtonComponent, TitleBlock } from './ui';
import { BlogSlider } from '.';
import { URLS } from '../utils';

const BlogSection = () => {
  const { t: translate } = useTranslation();

  return (
    <section
      id="blog"
      className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 py-16 px-8"
    >
      <TitleBlock title={translate('titles.blog')} />

      <BlogSlider />

      <CustomButtonComponent size="large" href={URLS.BLOG}>
        {translate('btns.seeAll')}
      </CustomButtonComponent>
    </section>
  );
};

export default BlogSection;
