const casesData = [
  {
    id: 1,
    name: { ru: 'Shopify CSV Аггрегатор', en: 'Shopify CSV Aggregator' },
    description: {
      ru: 'Это встроенное приложение Shopify стало настоящим прорывом в автоматизации работы с каталогами. Оно позволяет агрегировать CSV файлы, предлагая мощные инструменты, такие как редактор, паттернматчер, конвертер и AI для интеллектуального анализа и автоматизации импорта и экспорта данных. В разработке использовались передовые технологии Fastify и Remix.js, которые обеспечивают высокую производительность и невероятную гибкость. Это приложение полностью изменило способ работы с каталогами в Shopify, позволяя пользователям экономить время и ресурсы, а также улучшать точность данных.',
      en: 'This built-in Shopify app is a breakthrough in catalog automation. It aggregates CSV files, offering powerful tools like an editor, pattern matcher, converter, and AI for intelligent analysis and automation of data import and export. It uses cutting-edge technologies from Fastify and Remix.js, which provide high performance and incredible flexibility. This app completely changed the way Shopify works with catalogs, allowing users to save time and resources, and improve data accuracy.',
    },
    backgroundColor: 'rgba(129, 206, 160, 0.3)',
  },
  {
    id: 2,
    name: { ru: 'Агрегатор люксовых авто', en: 'Luxury Car Aggregator' },
    description: {
      ru: 'Уникальный агрегатор люксовых автомобилей для аренды, который был создан с использованием новейших технологий Next.js и Nest.js. Этот сайт не просто помогает пользователям находить и арендовать автомобили, он предлагает умные функции подбора, учитывающие предпочтения пользователя, историю запросов и доступность автомобилей в реальном времени. Проект демонстрирует, как можно интегрировать современные веб-технологии для создания высококачественного пользовательского опыта, который отвечает самым высоким требованиям. Благодаря своему интерфейсу и продуманным алгоритмам, агрегатор стал важным инструментом для любителей роскошных автомобилей.',
      en: 'A unique aggregator of luxury cars for rent, which was created using the latest technologies Next.js and Nest.js. This site does not just help users find and rent cars, it offers smart selection features that take into account user preferences, search history and real-time availability of cars. The project demonstrates how to integrate modern web technologies to create a high-quality user experience that meets the highest requirements. Thanks to its interface and well-thought-out algorithms, the aggregator has become an essential tool for luxury car lovers.',
    },
    backgroundColor: 'rgba(95, 111, 170, 0.3)',
  },
  {
    id: 3,
    name: { ru: 'Госпортал с системой баллов', en: 'State portal with a points system' },
    description: {
      ru: 'Этот государственный портал представляет собой инновационное решение для вовлечения граждан в общественные мероприятия. С использованием технологий Strapi и Next.js, мы создали платформу, которая не только обеспечивает удобный доступ к государственным услугам, но и мотивирует пользователей с помощью системы баллов. Участие в мероприятиях поощряется начислением баллов, которые затем можно обменивать на различные награды и привилегии. Это решение стало ключевым элементом в стратегии вовлечения граждан и демонстрирует, как современные технологии могут быть использованы для повышения гражданской активности и создания более вовлеченного общества.',
      en: 'This government portal is an innovative solution for citizen engagement in civic activities. Using Strapi and Next.js technologies, we created a platform that not only provides convenient access to government services, but also motivates users with a points system. Participation in activities is rewarded by accruing points, which can then be exchanged for various rewards and privileges. This solution has become a key element in the citizen engagement strategy and demonstrates how modern technologies can be used to increase civic engagement and create a more engaged society.',
    },
    backgroundColor: 'rgba(193, 148, 68, 0.3)',
  },
];

export default casesData;
