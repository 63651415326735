import React, { createContext, useState, useEffect, useContext } from 'react';
import i18n from 'i18next';

const STORAGE_LANGUAGE_KEY = 'language';
const LANGUAGE_RU = 'ru';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => localStorage.getItem(STORAGE_LANGUAGE_KEY) || LANGUAGE_RU);

  const toggleLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem(STORAGE_LANGUAGE_KEY, lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem(STORAGE_LANGUAGE_KEY);
    if (savedLanguage) {
      setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    } else {
      localStorage.setItem(STORAGE_LANGUAGE_KEY, LANGUAGE_RU);
      i18n.changeLanguage(LANGUAGE_RU);
    }
  }, []);

  return <LanguageContext.Provider value={{ language, toggleLanguage }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
