import React from 'react';
import { useLanguage } from '../../context/langContext';

const SelectLanguage = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <div className="flex gap-2 justify-center">
      <span
        className={`nav-link cursor-pointer ${language === 'ru' ? 'text-[#81CEA0]' : 'text-[#7e7f9a]'}`}
        onClick={() => toggleLanguage('ru')}
      >
        RU
      </span>
      <span className="text-[#7e7f9a]">/</span>
      <span
        className={`nav-link cursor-pointer ${language === 'en' ? 'text-[#81CEA0]' : 'text-[#7e7f9a]'}`}
        onClick={() => toggleLanguage('en')}
      >
        EN
      </span>
    </div>
  );
};

export default SelectLanguage;
