import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t: translate } = useTranslation();

  return (
    <footer
      className="relative bg-gray-200 bg-opacity-20 backdrop-blur-sm text-black py-2 px-20 rounded-lg w-full"
      style={{
        boxShadow: '0 -8px 20px -8px rgba(0, 0, 0, 0.2), 0 8px 20px -8px rgba(0, 0, 0, 0.05)',
        maxWidth: '100vw',
        fontSize: '0.75rem',
      }}
    >
      <p className="text-center whitespace-nowrap">&copy;{translate('footer')}</p>
    </footer>
  );
};

export default Footer;
