import denisImage from '../assets/team/den.jpg';
import evgenyImage from '../assets/team/jek.jpg';
import kateImage from '../assets/team/kate.jpg';
import germanImage from '../assets/team/ger.jpg';
import dimaImage from '../assets/team/dima.jpg';

const teamMembersData = [
  {
    id: 1,
    name: { ru: 'Денис', en: 'Denis' },
    position: { ru: 'Lead Full-Stack Engineer', en: 'Lead Full-Stack Engineer' },
    professionalExperience: {
      ru: 'Специализируется на разработке высоконагруженных веб-приложений и микросервисов',
      en: 'Specializes in developing high-load web applications and microservices',
    },
    avatar: denisImage,
  },
  {
    id: 2,
    name: { ru: 'Женя', en: 'Zhenya' },
    position: { ru: 'Lead Dev-ops Engineer', en: 'Lead Dev-ops Engineer' },
    professionalExperience: {
      ru: 'Эксперт в автоматизации CI/CD процессов и оркестрации контейнеров.',
      en: 'Expert in CI/CD process automation and container orchestration.',
    },
    avatar: evgenyImage,
  },
  {
    id: 3,
    name: { ru: 'Катя', en: 'Kate' },
    position: { ru: 'Lead Shopify expert & Frontend developer', en: 'Lead Shopify expert & Frontend developer' },
    professionalExperience: {
      ru: 'Опытный разработчик с акцентом на Shopify и создание пользовательских тем и приложений.',
      en: 'Experienced developer with a focus on Shopify and building custom themes and apps.',
    },
    avatar: kateImage,
  },
  {
    id: 4,
    name: { ru: 'Герман', en: 'Hermann' },
    position: { ru: 'Sales & account manager', en: 'Sales & account manager' },
    professionalExperience: {
      ru: 'Успешно управляет клиентскими аккаунтами и развивает продажи, обеспечивая рост выручки компании.',
      en: `Successfully manages client accounts and develops sales, ensuring the growth of the company's revenue.`,
    },
    avatar: germanImage,
  },
  {
    id: 5,
    name: { ru: 'Дима', en: 'Dima' },
    position: { ru: 'Frontend developer', en: 'Frontend developer' },
    professionalExperience: {
      ru: 'Разработчик в создании адаптивных пользовательских интерфейсов.',
      en: 'Developer in creating adaptive user interfaces.',
    },
    avatar: dimaImage,
  },
  {
    id: 6,
    name: { ru: 'сотрудников по всему миру', en: 'employees worldwide' },
    position: { ru: '', en: '' },
    professionalExperience: { ru: '', en: '' },
    avatar: null,
    count: '20+',
  },
];

export default teamMembersData;
