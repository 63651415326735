import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AboutSection,
  ServicesSection,
  CasesSection,
  TeamSection,
  BlogSection,
  TestimonialsSection,
  ContactSection,
  PageLayout,
} from '../components';
import { FRONTEND_URL } from '../utils';

const MainPage = () => {
  const { t: translate } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.targetSection) {
      const section = document.getElementById(location.state.targetSection);

      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Zennovaton - IT company</title>
        <meta name="description" content={translate('seo.mainDesc')} />
        <link rel="canonical" href={`${FRONTEND_URL}`} />
      </Helmet>
      <PageLayout>
        <AboutSection />
        <ServicesSection />
        <CasesSection />
        <TeamSection />
        <BlogSection />
        <TestimonialsSection />
        <ContactSection />
      </PageLayout>
    </>
  );
};

export default MainPage;
