import React from 'react';
import { ArticleDates } from '.';
import { BACKEND_URL, decodeHtmlEntities } from '../utils';
import { marked } from 'marked';
import { useLanguage } from '../context/langContext';

const SingleArticleContent = ({ post }) => {
  const { language } = useLanguage();

  if (!post?.attributes) return null;

  const { image, title, titleEn, publishedAt, updatedAt, description, descriptionEn, fullText, fullTextEn } =
    post.attributes;

  const localizedTitle = language === 'ru' ? title : titleEn;
  const localizedDescription = language === 'ru' ? description : descriptionEn;
  const localizedFullText = language === 'ru' ? fullText : fullTextEn;

  return (
    <>
      <ArticleDates publishedAt={publishedAt} updatedAt={updatedAt} />
      {image?.data?.attributes?.formats?.large?.url && (
        <div className="w-full bg-white rounded-2xl mb-4">
          <img
            src={`${BACKEND_URL}${image.data.attributes.formats.large.url}`}
            alt={image?.data?.attributes?.alternativeText ?? localizedTitle}
            className="w-full h-auto max-h-[720px] rounded-2xl object-cover"
            style={{
              objectFit: 'cover',
              maxWidth: '100%',
              maxHeight: '720px',
            }}
          />
        </div>
      )}
      {localizedDescription && <div className="mb-8 text-gray-500 italic">{localizedDescription}</div>}
      {localizedFullText && (
        <div
          className="mt-8 single-article"
          dangerouslySetInnerHTML={{
            __html: marked(decodeHtmlEntities(localizedFullText)),
          }}
        />
      )}
    </>
  );
};

export default SingleArticleContent;
