import albaImage from '../assets/testimonials/alba.webp';
import amirImage from '../assets/testimonials/amir.jpg';
import nikolaiImage from '../assets/testimonials/nikolai.png';

const testimonialsData = [
  {
    id: 1,
    name: { ru: 'Альба Фигуэрас', en: 'Alba Figueras' },
    position: 'Co-founder & COO',
    company: 'Her-age',
    borderColor: 'rgba(193, 148, 68, 0.3)',
    photo: albaImage,
    content: {
      ru: 'Отличная поддержка и быстрый сервис! Я очень довольна итоговым результатом.',
      en: 'Great support and fast service! I am very happy with the final result.',
    },
  },
  {
    id: 2,
    name: { ru: 'Амир Хаддади', en: 'Amir Haddadi' },
    position: 'IT project specialist',
    company: 'Piceci Services',
    borderColor: 'rgba(95, 111, 170, 0.3)',
    photo: amirImage,
    content: {
      ru: 'Очень профессиональный подход и отличные результаты. Работать с Денисом и его командой одно удовольствие.',
      en: 'Very professional approach and excellent results. It is a pleasure to work with Denis and his team.',
    },
  },
  {
    id: 3,
    name: { ru: 'Николай Иванов', en: 'Nikolay Ivanov' },
    position: 'Предприниматель',
    company: 'Transavto',
    borderColor: 'rgba(129, 206, 160, 0.3)',
    photo: nikolaiImage,
    content: {
      ru: 'Рекомендую! Быстрая работа и отличное качество - это все про ZNN.',
      en: `I recommend! Fast work and excellent quality - that's all about ZNN.`,
    },
  },
];

export default testimonialsData;
